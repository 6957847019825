.box1 {
	/* min-width: 100%; */
	height: 100%;
	background-color: #fff;
	/* margin: 2rem; */
	margin-top: 1rem;
	margin-left: 0%;
	border-radius: 12px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: auto;
}

.box2 {
	border-radius: 12px;
	border: 1px dashed rgba(67, 122, 179, 0.6);
	width: 100%;
	margin-right: 3%;
	display: flex;
	padding: 10px;
}

.box3 {
	display: flex;
	align-items: center;
	min-width: max-content;
	justify-content: space-between;
}

.box4 {
	display: flex;
	align-items: center;
	justify-content: start;
	min-width: max-content;
	width: -webkit-fill-available;
	width: -moz-available;
}

.mr5 {
	margin-right: 5px;
	margin-bottom: 0px;
}

.text8 {
	text-align: right;
	font-family: font;
	font-size: 0.99rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	/* margin-right: 5%; */
	text-align: center;
	color: #080d2b;
	align-items: center;
}

.text9 {
	text-align: right;
	font-family: font;
	font-size: 0.85rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #080d2b;
}

.text10 {
	text-align: right;
	font-family: font;
	font-size: 0.83rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #014a97;
}

.text11 {
	text-align: right;
	font-family: font;
	font-size: 0.7rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #080d2b99;
}

.text12 {
	text-align: right;
	font-family: font;
	font-size: 0.83rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #1a1a1a;
}

.text13 {
	font-family: font;
	font-size: 0.7rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	/* margin-right: 5%; */
	text-align: center;
	color: #014a97;
}

.text14 {
	text-align: right;
	font-family: font;
	font-size: 0.83rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 2%;
	text-align: center;
	color: #1a1a1a;
}

.border {
	border-bottom: 1px solid var(--form-stroke, #e7e7e7);
	height: 2px;
	width: 100%;
}

.shape1 {
	width: 15px;
	height: 15px;
	border-radius: 20px;
	background: #27ae60;
	margin-top: 1%;
}

.shape2 {
	width: 15px;
	height: 15px;
	border-radius: 20px;
	background: #ffc300;
	margin-top: 1%;
}

.shape3 {
	width: 8px;
	height: 8px;
	border-radius: 20px;
	background: var(--form-stroke, #a7a5a5);
}

.shape4 {
	width: 15px;
	height: 15px;
	border-radius: 20px;
	background: #ff1f00;
	margin-top: 1%;
}

.h0 {
	height: auto;
}

.width-available {
	width: -webkit-fill-available;
	width: -moz-available;
}

.flex-center {
	display: flex;
	align-items: center;
	/* justify-content: center; */
}

@media only screen and (max-width: 390px) {
	.wc400px {
		margin-right: 20px !important;
	}
	/* .box1 {
        transform: scale(0.85);
    } */
	.css-jhuyf4-MuiGrid-root > .MuiGrid-item {
		padding-left: 0px !important;
	}
	.un-scale {
		transform: scale(0.85);
	}
}

.css-1blkw4v-MuiResponsiveChart-container {
	height: 100px !important;
	width: 200px !important;
	/* margin-top: 0%; */
}

.css-155wf22 {
	height: 100% !important;
	width: 100% !important;
}

.css-gp36re-MuiGrid-root > .MuiGrid-item {
	padding-top: 0px !important;
}

.box40 {
	display: flex;
	flex-direction: column;
	justify-content: start;
}

.box41 {
	display: flex;
	gap: 5px;
	justify-content: space-between;
}

.box43 {
	display: flex;
	flex-direction: column;
}

.box44 {
	display: flex;
	/* width: 255px; */
	padding: 12px;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-radius: 8px;
	background: #f4f7ff;
	margin-top: 10px;
}

.box42 {
	border-radius: 8px;
	border: 1px solid var(--form-stroke, #e7e7e7);
	display: flex;
	padding: 8px;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	gap: 8px;
	flex: 1 0 0;
	margin-top: 10px;
}

.box45 {
	width: inherit;
	margin-left: 10px;
}

.text-r {
	text-align: right;
	width: max-content;
}

.text-b {
	color: #080d2b;
}

.text-green {
	color: #27ae60;
}

.css-3ipe9i-MuiGrid-root > .MuiGrid-item {
	margin-top: 0% !important;
}

.text60 {
	font-family: font;
	font-size: 1rem;
	margin-bottom: 10px;
	color: #014e97;
	/* font-weight: 700; */
}

.box61 {
	display: flex;
	border-radius: 8px;
	border: 1px solid var(--form-stroke, #e7e7e7);
	margin-top: 10px;
	padding: 5px;
}

.box62 {
	min-width: max-content;
	border-left: solid 1px #e7e7e7;
	padding: 10px;
}

.pt0 {
	padding-top: 0px !important;
}

.renter-req-root {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: 25vh;
	gap: 30px;
}

.box10 {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
}

.box11 {
	padding: 20px;
	min-width: 10vw;
	display: flex;
	flex-direction: column;
	direction: rtl;
}

.box12 {
	height: 25vh;
	width: 100%;
	border-radius: 8px;
	border: 1px dashed #d8d8d8;
	background: #f5f7ff;
	margin-top: 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.box13 {
	fill: #014e97;
	background-color: #014e97;
	stroke-width: 1px;
	stroke: var(--Stroke, #e7e7e7);
	width: 40.537px;
	height: 40px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.req-root {
	margin-inline: 20px;
}

.box14 {
	/* min-width: 100%; */
	height: 100%;
	background-color: #fff;
	/* margin: 2rem; */
	margin-top: 1rem;
	/* margin-left: 0%; */
	border-radius: 12px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.box15 {
	min-width: 80%;

	/* height: 100%; */
	background-color: #f1f4ff;
	/* margin: 2rem; */
	margin-top: 1rem;
	margin-left: 20px;
	border-radius: 6px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.box15-1 {
	min-width: 80%;
	background-color: #f1f4ff;
	/* margin-top: 1rem; */
	/* margin-left: 20px; */
	border-radius: 6px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.box16 {
	min-width: 80%;

	/* height: 100%; */
	background-color: #014e97;
	/* margin: 2rem; */
	margin-top: 1rem;
	margin-right: 20px;
	border-radius: 6px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	/* align-items: center; */
}
.box16-green {
	min-width: 97%;
	/* height: 100%; */
	background-color: #219653;
	/* margin: 2rem; */
	margin-top: 1rem;
	margin-right: 20px;
	border-radius: 6px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	/* align-items: center; */
}

.box17 {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.box18 {
	min-width: 80%;

	/* height: 100%; */
	background-color: #f1f4ff;
	/* margin: 2rem; */
	margin-top: 1rem;
	margin-left: 20px;
	border-radius: 6px;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.box19 {
	display: flex;
	width: 120px;
	height: 50px;
	/* padding: 12px; */
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 6px;
	border: 1px solid var(--form-stroke, #e7e7e7);
}

.box20 {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.box30 {
	display: flex;
	height: 54px;
	flex-direction: column;
	justify-content: center;
	flex: 1 0 0;
	color: #014a97;
	text-align: center;
	font-family: font;
	font-size: 14px;
	font-style: normal;
	/* font-weight: 700; */
	/* line-height: 78px; */
}

.box31 {
	width: 300px;
	height: 50px;
	background-color: #f1f4ff;
	display: flex;
	margin-right: 20px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.box32 {
	display: flex;
}

.box33 {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--form-stroke, #e7e7e7);
	background: #fff;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	width: 50px;
}

.box34 {
	margin-top: 20px;
	background-color: #fff;
	border: none;
	/* padding-block: 20px; */
	width: auto;
}

.shape10 {
	background-color: #83a8cf;
	border-radius: 50%;
}

.text20 {
	color: rgba(1, 28, 80, 0.65);
	text-align: center;
	font-family: font;
	font-size: 16px;
	font-style: normal;
	/* font-weight: 700; */
	line-height: 27.2px; /* 170% */
}

.text21 {
	color: #004a98;
	text-align: center;
	font-family: font;
	font-size: 14px;
	font-style: normal;
	/* font-weight: 700; */
}

.text22 {
	text-align: right;
	font-family: font;
	font-size: 1rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #080d2b;
}

.text23 {
	text-align: right;
	font-family: font;
	font-size: 0.85rem;
	font-style: normal;
	/* font-weight: 500; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	text-align: center;
	color: #4f4f4f;
}

.text23-1 {
	text-align: right;
	font-family: font;
	font-size: 0.85rem;
	font-style: normal;
	/* font-weight: 500; */
	display: flex;
	margin-top: 0%;
	/* margin-right: 5%; */
	margin-block: 0px 5px;
	text-align: center;
	color: #4f4f4f;
}

.text24 {
	color: #fff;
	text-align: right;
	font-family: font;
	font-size: 0.8rem;
	font-style: normal;
	/* font-weight: 500;/ */
	margin-right: 10px;
}

.text25 {
	text-align: right;
	font-family: font;
	font-size: 0.85rem;
	font-style: normal;
	/* font-weight: 500; */
	display: flex;
	margin-top: 0%;
	margin-right: 5%;
	width: max-content;
	color: #080d2b;
}

.text26 {
	text-align: right;
	font-family: font;
	font-size: 0.99rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	text-align: center;
	color: #080d2b;
}

.slb-text {
	font-size: 0.8rem;
	font-family: font;
	margin-bottom: 3px;
	margin-top: 5px;
}

.center {
	justify-content: start;
}

.normal-step {
	text-align: right;
	font-family: font;
	font-size: 0.6rem;
	font-style: normal;
	/* font-weight: 600; */
	display: flex;
	margin-top: 0%;
	/* margin-right: 5%; */
	text-align: center;
	/* color: #014a97; */
}

.countdown-timer {
	font-size: 1rem;
	/* color: burlywood; */
	font-family: font;
	display: flex;
	gap: 10px;
	direction: rtl;
	background-color: #f1f4ff;
	padding: 15px;
	border-radius: 6px;
}

.timer-min {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media only screen and (max-width: 600px) {
	.renter-req-root {
		height: 78vh;
		margin-top: 0vh;
	}

	.displayNon-600 {
		display: none;
	}

	.box16 {
		min-width: auto;
	}

	.pay-icon {
		scale: calc(100vw / 1100);
	}
	.box19 {
		width: min-content;
	}
	.vw100 {
		width: 100vw;
	}
	.overF-auto {
		overflow: auto;
	}
	.mr0 {
		margin-right: 0px;
	}
	.fx-dir {
		flex-direction: column;
	}
	.s09 {
		scale: 0.9;
	}
	.mh-100vh {
		min-height: 100vh !important;
	}
	.w70 {
		width: 70% !important;
	}
}

.box50 {
	width: -webkit-fill-available;
	width: -moz-available;
	height: min-content;
}

.box51 {
	flex-direction: row;
}

.box52 {
	display: flex;
	align-items: center;
	justify-content: center;
}

.box53 {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 6px;
	direction: rtl;
	padding: 20px;
	gap: 20px;
}

.box54 {
	display: flex;
	/* justify-content: start; */
	align-items: start;
	flex-direction: column;
}

.text50 {
	/* font-weight: 500; */
	font-size: 0.85rem;
}

.text55 {
	font-size: 1.5rem;
	border-bottom: solid 1px #e7e7e7;
	padding-bottom: 20px;
}

.text56 {
	text-align: right;
}

.box56 {
	padding: 10px;
	margin-left: 50px;
}

.rdw-block-wrapper {
	display: none !important;
}
.rdw-fontfamily-wrapper {
	display: none !important;
}
.rdw-fontsize-wrapper {
	display: none !important;
}
.rdw-colorpicker-wrapper {
	display: none !important;
}
.rdw-image-wrapper {
	display: none !important;
}
.rdw-emoji-wrapper {
	display: none !important;
}
.rdw-embedded-wrapper {
	display: none !important;
}

/* .bold {
    font-weight: 600;
} */

.pro-img {
	width: 3.125rem;
	height: 3.125rem;
	border-radius: 3.125rem;
	border: 1px solid var(--form-stroke, #e7e7e7);
}

.pro-box {
	display: flex;
	height: 3.125vmax;
	justify-content: flex-end;
	align-items: center;
	gap: 1vmax;
	width: 100%;
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
	font-family: font-normal !important;
}

.css-1v4ccyo {
	font-family: font-normal !important;
}

.MuiInputBase {
	font-family: font-normal !important;
}

.box-1 {
	display: flex;
	gap: 5px;
	width: 100%;
	border-bottom: solid 1px #e7e7e7;
	height: 100%;
	align-items: center;
	justify-content: center;
	/* width: 10vw; */
}

.box-2 {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	width: 100%;
}

.box-3 {
	direction: rtl;
	width: 100%;
}

.box-4 {
	background-color: #fff;
	border-radius: 9px;
	border: 1px solid rgba(67, 122, 179, 0.45);
}

.box-5 {
	padding-bottom: 0%;
}

.text-1 {
	margin-right: 0%;
}

.m0 {
	margin: 0%;
}

.orange {
	color: #f2994a;
}

.admin-box1 {
	background-color: #fff;
	border-radius: 8px;
	width: 100%;
}
.admin-box2 {
	background-color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield !important;
}
