.pro-img {
	width: 3.125rem;
	height: 3.125rem;
	border-radius: 3.125rem;
	border: 1px solid var(--form-stroke, #e7e7e7);
}

.pro-box {
	display: flex;
	height: 3.125vmax;
	justify-content: flex-end;
	align-items: center;
	gap: 1vmax;
	width: 100%;
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
	font-family: font-normal !important;
}

.css-1v4ccyo {
	font-family: font-normal !important;
}

.MuiInputBase {
	font-family: font-normal !important;
}
