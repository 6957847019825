@media only screen and (max-width: 400px) {
  /* .scale {
		transform: scale(0.95);
	} */
}

@media only screen and (max-width: 375px) {
  /* .scale {
    transform: scale(0.9);
  } */
  .root-m {
    padding: 0px !important;
  }
}
@media only screen and (max-width: 500px) {
  .root-m {
    margin-inline: 10px !important;
  }

  .vw90 {
    width: 90vw;
  }

  .flex-c-r {
    flex-direction: column-reverse !important;
  }

  .w100 {
    width: 100% !important;
  }
}

.f-normal {
  font-family: font-normal !important;
}

@media only screen and (min-width: 401px) and (max-width: 500px) {
  /* .scale-r {
		transform: scale(0.96);
	} */
}

@font-face {
  font-family: "font";
  src: local("font"), url(./assets/fonts/font-bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-med";
  src: local("font-med"), url(./assets/fonts/font-med.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-normal";
  src: local("font-normal"), url(./assets/fonts/font-med.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cairo";
  src: local("cairo"), url(./assets/fonts/Cairo-Bold.ttf) format("woff");
}
